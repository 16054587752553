/* ==========================================================================

  Table of content:

  1. Custom mouse cursor
  2. Animsition preloader
  3. Swiper slider
  4. Isotope
  5. Scroll animations
  6. Navigation open/close
  7. Drop-down menu
  8. Change menu background
  9. Magnific popup
  10. Touch, no touch
  11. Contact form
  12. Marquee
  13. Splitting
  14. SplitLines

  ========================================================================== */
$(function () {
  "use strict";
  const mHTML = document.getElementById("message"),
    messages = [
      "News that has the potential to impact a large number of people",
      "Rare news pieces (Historical/Present/Future)",
      "Coming from a popular person",
      "Exclusive trailers before the actual piece comes out",
      "Important analysis/data points/sources (utility)",
    ];
  let currentMessage = 0;
  function typeMessage() {
    if (!messages[currentMessage]) {
      currentMessage = 0;
    }
    const currentStr = messages[currentMessage];
    currentStr.split();
    let part = "";
    let currentLetter = 0;
    let int1 = setInterval(() => {
      if (!currentStr[currentLetter]) {
        currentMessage++;
        setTimeout(() => {
          deleteMessage(part);
        }, 500);
        clearInterval(int1);
      } else {
        part += currentStr[currentLetter++];
        mHTML.innerHTML = part;
      }
    }, 100);
  }
  function deleteMessage(str) {
    let int = setInterval(() => {
      if (str.length === 0) {
        setTimeout(() => {
          typeMessage();
        }, 200);
        clearInterval(int);
      } else {
        str = str.split("");
        str.pop();
        str = str.join("");
        mHTML.innerHTML = str;
      }
    }, 30);
  }
  if (typeof mHTML != "undefined" && mHTML != null) {
    typeMessage();
  }
  /* 1. Custom mouse cursor */
  document
    .getElementsByTagName("body")[0]
    .addEventListener("mousemove", function (n) {
      (e.style.left = n.clientX + "px"), (e.style.top = n.clientY + "px");
    });
  var e = document.getElementById("js-pointer");

  $(document).mousemove(function (e) {
    $(
      ".js-pointer-large, .swiper-button-next, .swiper-button-prev, .mfp-arrow-left, .mfp-arrow-right"
    )
      .on("mouseenter", function () {
        $(".js-pointer").addClass("js-large");
      })
      .on("mouseleave", function () {
        $(".js-pointer").removeClass("js-large");
      });

    $(".js-pointer-small, .swiper-pagination-bullet")
      .on("mouseenter", function () {
        $(".js-pointer").addClass("js-small");
      })
      .on("mouseleave", function () {
        $(".js-pointer").removeClass("js-small");
      });

    $(".js-pointer-drag")
      .on("mouseenter", function () {
        $(".js-pointer").addClass("js-drag");
      })
      .on("mouseleave", function () {
        $(".js-pointer").removeClass("js-drag");
      });

    $(".js-pointer-zoom")
      .on("mouseenter", function () {
        $(".js-pointer").addClass("js-zoom");
      })
      .on("mouseleave", function () {
        $(".js-pointer").removeClass("js-zoom");
      });

    $(".js-pointer-none")
      .on("mouseenter", function () {
        $(".js-pointer").addClass("js-none");
      })
      .on("mouseleave", function () {
        $(".js-pointer").removeClass("js-none");
      });
  });

  $(document).ready(function () {
    /* 2. Animsition preloader */
    $(".js-animsition-overlay").animsition({
      inClass: "overlay-slide-in-right",
      outClass: "overlay-slide-out-right",
      inDuration: 1,
      outDuration: 1500,
      linkElement: ".js-animsition-link",
      // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
      loading: false,
      loadingParentElement: "body", //animsition wrapper element
      loadingClass: "animsition-loading",
      loadingInner: "", // e.g '<img src="loading.svg" />'
      timeout: false,
      timeoutCountdown: 5000,
      onLoadEvent: true,
      browser: ["animation-duration", "-webkit-animation-duration"],
      // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
      // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
      overlay: true,
      overlayClass: "js-animsition-overlay-slide",
      overlayParentElement: "body",
      transition: function (url) {
        window.location.href = url;
      },
    });

    $("body").on("animsition.outStart", function () {
      $("body").removeClass("js-page-in-out");
      $("body").addClass("js-page-out");
    });

    $("body").on("animsition.inEnd", function () {
      $("body").addClass("js-page-in-out");
      $("body").addClass("js-page-in");
      setTimeout(function () {
        $("body").addClass("js-page-in-anim-active");
      }, 1000);

      /* 3. Swiper slider */
      var interleaveOffset = 0.5;

      var homeSwiper = new Swiper(".js-home-slider", {
        speed: 1300,
        watchSlidesProgress: true,
        simulateTouch: false,
      });

      var infiniteSwiper = new Swiper(".js-infinite-slider", {
        slidesPerView: 6,
        spaceBetween: 0,
        speed: 5000,
        loop: true,
        autoplay: {
          delay: 0,
        },
        breakpoints: {
          320: {
            slidesPerView: 2,
          },
          600: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1240: {
            slidesPerView: 4,
          },
        },
      });

      var teamSwiper = new Swiper(".js-team-slider", {
        slidesPerView: 6,
        spaceBetween: 0,
        breakpoints: {
          320: {
            slidesPerView: 2,
          },
          600: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
          1240: {
            slidesPerView: 4,
          },
        },
      });

      /* 5. Scroll animations */
      var scroll = new LocomotiveScroll({
        el: document.querySelector("#js-scroll-content"),
        smooth: true,
      });
    });

    /* 6. Navigation open/close */
    $(".js-menu-open-close").on("click", function () {
      $("body").toggleClass("js-nav-active");
    });

    $(".js-landing-close").on("click", function () {
      $("body").removeClass("js-nav-active");
    });

    /* 7. Drop-down menu */
    $(".js-dropdown-open").on("click", function () {
      $(".js-dropdown-active-box").addClass("js-dropdown-is-active");
      $(this).find(".js-dropdown").addClass("js-dropdown-is-active");
    });

    $(".js-dropdown-close").on("click", function () {
      $(".js-dropdown-active-box").removeClass("js-dropdown-is-active");
      $(".js-dropdown").removeClass("js-dropdown-is-active");
    });

    $(".js-dropdown-open-2lvl").on("click", function () {
      $(".js-dropdown-active-box").addClass("js-dropdown-is-active-2lvl");
      $(this).find(".js-dropdown-2lvl").addClass("js-dropdown-is-active-2lvl");
    });

    $(".js-dropdown-close-2lvl").on("click", function () {
      $(".js-dropdown-active-box").removeClass("js-dropdown-is-active-2lvl");
      $(".js-dropdown-2lvl").removeClass("js-dropdown-is-active-2lvl");
    });
  });
  $(document).ready(function () {
    /* 8. Change menu background */
    $(document).on("mouseover", ".js-nav-bg-change", function () {
      $(this)
        .addClass("js-nav-bg-active")
        .siblings()
        .removeClass("js-nav-bg-active");
    });

    /* 9. Magnific popup */

    $(".js-popup").magnificPopup({
      type: "inline",
      closeBtnInside: true,
      autoFocusLast: true,
      focus: ".modal-title",
      mainClass: "mfp-fade",
      removalDelay: 500,
      preloader: false,
      callbacks: {
        beforeOpen: function () {
          $("body").css("overflow", "hidden");
        },
        beforeClose: function () {
          $("body").css("overflow", "auto");
        },
      },
    });

    // $(".js-photo-popup").magnificPopup({
    //   type: "image",
    //   mainClass: "mfp-fade",
    //   gallery: {
    //     enabled: true,
    //   },
    //   removalDelay: 1000,
    //   autoFocusLast: false,
    //   preloader: false,
    //   callbacks: {
    //     beforeOpen: function () {
    //       $("body").css("overflow", "hidden");
    //     },
    //     beforeClose: function () {
    //       $("body").css("overflow", "auto");
    //     },
    //   },
    // });

    // $(".js-popup-youtube").magnificPopup({
    //   disableOn: 700,
    //   type: "iframe",
    //   mainClass: "mfp-fade",
    //   removalDelay: 1000,
    //   autoFocusLast: false,
    //   preloader: false,
    //   callbacks: {
    //     beforeOpen: function () {
    //       $("body").css("overflow", "hidden");
    //     },
    //     beforeClose: function () {
    //       $("body").css("overflow", "auto");
    //     },
    //   },
    // });
    $(".tab-link").click(function () {
      var tabID = $(this).attr("data-tab");

      $(this).addClass("active").siblings().removeClass("active");

      $("#tab-" + tabID)
        .addClass("active")
        .siblings()
        .removeClass("active");
    });
  });

  /* 10. Touch, no touch */
  var supports = (function () {
    var d = document.documentElement,
      c = "ontouchstart" in window || navigator.msMaxTouchPoints;
    if (c) {
      d.className += " js-touch";
      return {
        touch: true,
      };
    } else {
      d.className += " js-no-touch";
      return {
        touch: false,
      };
    }
  })();

  /* 12. Marquee */
  $(".marquee").marquee({
    //duration in milliseconds of the marquee
    duration: 15000,
    //gap in pixels between the tickers
    gap: 50,
    //time in milliseconds before the marquee will start animating
    delayBeforeStart: 0,
    //'left' or 'right'
    direction: "left",
    //true or false - should the marquee be duplicated to show an effect of continues flow
    duplicated: true,
  });

  Splitting();
  /* 14. SplitLines */
  $(".anim-split-lines").each(function () {
    $(this).splitLines({
      tag: '<div class="animated-line-wrapper"><div class="animated-line-content">',
      keepHtml: true,
    });
  });
});
